


























































































































































































































import SearchBuilder from "@/builder/SearchBuilder";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectUser from "@/components/custom/select/SelectUser.vue";
import dateFormat from "@/filters/date.filter";
import { APagination } from "@/hooks/table";
import useBlob from "@/hooks/useBlob";
import ContractReminderMapper from "@/mapper/ContractReminder.mapper";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ContractReminderResponseDto } from "@/models/interface/contract-reminder.interface";
import { contractReminderService } from "@/services/contract-reminder.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";

interface Filter {
  createdBy?: LabelInValue;
  unitCode: string;
  customer?: LabelInValue;
}

interface Row extends ContractReminderResponseDto {
  rentPeriod: string;
}

@Component({
  components: {
    SelectCustomer,
    SelectUser,
  },
})
export default class ContractReminder extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Ref("formModel")
  formModel!: FormModel;

  defaultFilters: Filter = {
    createdBy: undefined,
    unitCode: "",
    customer: undefined,
  };
  defaultData: Pagination<Row> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  form: Filter = { ...this.defaultFilters };
  loading = {
    overdue: false,
    reminder: false,
    download: false,
  };
  overdueContractReminders: Pagination<Row> = {
    ...this.defaultData,
  };
  reminderContractReminders: Pagination<Row> = {
    ...this.defaultData,
  };
  overduePagination = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
    getParams(): RequestQueryParams {
      const params = new RequestQueryParams();
      params.limit = this.limit;
      params.page = this.page - ONE;
      return params;
    },
  };
  reminderPagination = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
    getParams(): RequestQueryParams {
      const params = new RequestQueryParams();
      params.limit = this.limit;
      params.page = this.page - ONE;
      return params;
    },
  };

  columns = [
    {
      title: this.$t("lbl_created_by"),
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
      key: "icNumber",
      scopedSlots: { customRender: "icNumber" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: this.$t("lbl_customer_number"),
      dataIndex: "customerNumber",
      key: "customerNumber",
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      scopedSlots: { customRender: "action" },
    },
  ];

  buildFormSearchParams(formFilter: Filter): string {
    const builder = new SearchBuilder();
    const filters: string[] = [];

    if (formFilter.unitCode) {
      filters.push(
        builder
          .push(["unitCode", formFilter.unitCode], { like: "both" })
          .build()
      );
    }
    if (formFilter.customer?.key) {
      filters.push(
        builder.push(["customerId", formFilter.customer.key]).build()
      );
    }
    if (formFilter.createdBy?.label) {
      filters.push(
        builder
          .push(["createdBy", formFilter.createdBy.label], { like: "both" })
          .build()
      );
    }

    return filters.join(builder.AND);
  }

  onSearch(): void {
    this.overduePagination.reset();
    this.reminderPagination.reset();

    const params = new RequestQueryParams();
    params.limit = DEFAULT_PAGE_SIZE;
    params.page = DEFAULT_PAGE - ONE;
    params.search = this.buildFormSearchParams(this.form);

    this.getOverdue({ ...params });
    this.getReminder({ ...params });
  }

  onFilterReset(): void {
    this.form = { ...this.defaultFilters };
    this.onSearch();
  }

  onOverdueTableChange(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.overduePagination.page =
      pageSize !== this.overduePagination.limit ? DEFAULT_PAGE : current;
    this.overduePagination.limit = pageSize;

    const params = this.overduePagination.getParams();
    params.search = this.buildFormSearchParams(this.form);

    this.getOverdue(params);
  }

  onReminderTableChange(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.reminderPagination.page =
      pageSize !== this.reminderPagination.limit ? DEFAULT_PAGE : current;
    this.reminderPagination.limit = pageSize;

    const params = this.reminderPagination.getParams();
    params.search = this.buildFormSearchParams(this.form);

    this.getReminder(params);
  }

  onDownload({ key }: { key: "all" | "overdue" | "reminder" }): void {
    const { toDownload } = useBlob();

    const params = new RequestQueryParams();
    params.search = this.buildFormSearchParams(this.form);
    params.sorts = "endRent:asc";
    if (key === "overdue" || key === "reminder") {
      const isExpired = key === "overdue";
      params.search = this.addExpiredFilter(params.search, isExpired);
    }

    const downloadParams =
      ContractReminderMapper.paginationParamToParamDownload(params, {
        createdBy: this.form.createdBy?.label,
        customer: this.form.customer?.label,
        unitCode: this.form.unitCode,
      });

    this.loading.download = true;
    contractReminderService
      .downloadContractReminders(downloadParams)
      .then(res => {
        toDownload(res, "contract_reminder.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  convertContractReminderResponseDTOToRow(
    reminder: ContractReminderResponseDto
  ): Row {
    return {
      ...reminder,
      rentPeriod: `${dateFormat(reminder.startRent)} - ${dateFormat(
        reminder.endRent
      )}`,
    };
  }

  getReminder(params: RequestQueryParams = new RequestQueryParams()): void {
    params.search = this.addExpiredFilter(params.search, false);
    params.sorts = "endRent:asc";

    this.loading.reminder = true;
    contractReminderService
      .getContractReminders(params)
      .then(res => {
        this.reminderContractReminders = {
          ...res,
          data: res.data.map(this.convertContractReminderResponseDTOToRow),
        };
        this.reminderContractReminders.currentPage += ONE;
      })
      .finally(() => {
        this.loading.reminder = false;
      });
  }

  getOverdue(params: RequestQueryParams = new RequestQueryParams()): void {
    params.search = this.addExpiredFilter(params.search, true);
    params.sorts = "endRent:asc";

    this.loading.overdue = true;
    contractReminderService
      .getContractReminders(params)
      .then(res => {
        this.overdueContractReminders = {
          ...res,
          data: res.data.map(this.convertContractReminderResponseDTOToRow),
        };
        this.overdueContractReminders.currentPage += ONE;
      })
      .finally(() => {
        this.loading.overdue = false;
      });
  }

  addExpiredFilter(searchFilter: string, expired: boolean): string {
    const builder = new SearchBuilder();
    const expiredFilter = builder
      .push(["isExpired", expired.toString()])
      .build();

    if (searchFilter) {
      return searchFilter + builder.AND + expiredFilter;
    } else {
      return expiredFilter;
    }
  }

  created(): void {
    this.getOverdue();
    this.getReminder();
  }
}
